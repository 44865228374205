import { useEffect } from "react";
import Breadcrumbs from "../../components/breadcrumbs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";
import headshotImage from "../../assets/img/headshot.png";

import Header from "../../components/header";
import "./resume.style.scss";

const Resume = () => {
  useEffect(() => {
    document.title = "andriantam | Resume";
  }, []);
  return (
    <>
      <Header isResume={true} />
      <main id="main">
        <section id="resume-hero">
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>Resume</h2>
          </div>
        </section>

        <Breadcrumbs secondLevelTitle="Resume" />
        <section id="resume" className="resume">
          <div className="container">
            <div className="section-title">
              <span>My Resume</span>
              <h2>My Resume</h2>
              <div className="row">
                <div className="col-lg-6">
                  <div className="headshot">
                    <LazyLoadImage
                      src={headshotImage}
                      alt=""
                      effect="blur"
                      className="headshot"
                    />
                  </div>
                </div>
                <div className="col-lg-6 biodata">
                  <h3>Andrian Tam</h3>
                  <p>Software Engineer</p>
                  <p>Photographer</p>
                  <p>Brand Builder</p>
                  <p>Kuala Lumpur, Malaysia</p>
                  <a href="mailto:andriantamwj@gmail.com">
                    andriantamwj@gmail.com
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <h3 className="resume-title">Professional Experience</h3>
              <div className="col-lg-6">
                <div className="resume-item">
                  <h4>ZBOM Malaysia</h4>
                  <h5>November 2024 - Present</h5>
                  <p>
                    <em>Agent</em>
                  </p>
                  <ul>
                    <li>Kitchen Cabinet and Wardrobe Specialist</li>
                  </ul>
                </div>

                <div className="resume-item">
                  <h4>Toge Business Solutions</h4>
                  <h5>July 2024 - Present</h5>
                  <p>
                    <em>Founder</em>
                  </p>
                  <ul>
                    <li>Photographer</li>
                    <li>Videographer</li>
                    <li>Events</li>
                    <li>Business Strategy Consultant</li>
                    <li>Marketing Consultant</li>
                  </ul>
                </div>

                <div className="resume-item">
                  <h4>McKinsey & Company</h4>
                  <h5>Jan 2018 - Jan 2024</h5>
                  <p>
                    <em>Senior Analyst (Digital Consulting) </em>
                  </p>

                  <ul>
                    <li>
                      Developed a carbon emission tracking tool for a
                      manufacturing client in Taiwan using NextJS and NestJS.
                    </li>
                    <li>
                      Built a dynamic eCommerce prototype webapp with analytics
                      tracking for an aviation client at Hong Kong in 1 week
                      that is deployable for both offline and online solutions.
                    </li>
                    <li>
                      Delivery Manager for a team of 6 engineers and 2 designers
                      to build a customizable Marketing Tech demo tool.
                    </li>
                    <li>
                      Built the front-end Ul and managed data ingestion on a
                      digital risk monitor app using React JS to C#, NET and EF
                      Core for a financial services client based in London and
                      USA, with a team based in Sydney.
                    </li>
                    <li>
                      Lead the development and engineered a consumer facing
                      digital lending website in Jakarta along with MarTech 360
                      integrations.
                    </li>
                    <li>
                      Developed and coached a team of 15 engineers in a
                      Malaysian digital lending transformation project written
                      on Angular, while collaborating with designers on
                      versioning for development and creating style guides for
                      theming.
                    </li>
                    <li>
                      Built a theme engine and modular component front-end
                      framework on React JS for a mining client in Jakarta.
                    </li>
                    <li>
                      Developed a server side rendered website for a telco
                      client in Bangkok involving migrations with NodeJs, Pug
                      and Sequelize.
                    </li>
                    <li>
                      Worked on a VR demo app for the mining practice with a
                      team based in Amsterdam.
                    </li>
                    <li>
                      Set up and strategized the architecture for marketing
                      tools like Google Analytics and Tag Manager, as well as
                      some integrations with OTS tools like Segment, Responsys,
                      Airship for a retail client in Bangkok
                    </li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>VLT Labs</h4>
                  <h5>Sept 2017 - Jan 2018</h5>
                  <p>
                    <em>Junior Software Engineer</em>
                  </p>

                  <ul>
                    <li>
                      Developed projects using Node.js with Mongoose (MongoDB)
                      and Hapi dependencies.
                    </li>
                    <li>
                      Executed website scrapers using Python Scrapy to populate
                      existing 3rd party web data into a database.
                    </li>
                    <li>
                      Worked on an e-commerce fashion site using Pug and SCSS
                      development frameworks.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="resume-item">
                  <h4>Isobar (Dentsu Aegis Group)</h4>
                  <h5>Feb 2016 - Sep 2017</h5>
                  <p>
                    <em>Full-stack Web Developer</em>
                  </p>

                  <ul>
                    <li>
                      Developed and maintained client websites which run with
                      HTML, CSS, Javascript, PHP and SQL.{" "}
                    </li>
                    <li>
                      Improved development culture by implementing MVC
                      frameworks such as Laravel for new client websites.
                    </li>
                    <li>
                      Dived into non-web development by creating an Infrared
                      trigger mechanism with Arduino as a HCI interface as an on
                      ground installation.
                    </li>
                    <li>Implemented cloud storage with AWS S3.</li>
                    <li>
                      In charge of setting up an ad-hoc video/photography
                      studio.
                    </li>
                    <li>
                      Reevaluate and redesigned maintenance development flows to
                      improve its process and reduce errors.
                    </li>
                  </ul>
                </div>

                <div className="resume-item">
                  <h4>Dekatku Sdn. Bhd.</h4>
                  <h5>Oct 2015 - Feb 2016</h5>
                  <p>
                    <em>Developer</em>
                  </p>

                  <ul>
                    <li>
                      Designed and delivered UI/UX and logos for clients
                      website.
                    </li>
                    <li>
                      Built company subsidiary website by implementing
                      responsive web methods such as Bootstrap.
                    </li>
                  </ul>
                </div>

                <div className="resume-item">
                  <h4>Samsung Electronics</h4>
                  <h5>Jul 2015 - Sep 2015</h5>
                  <p>
                    <em>Intern</em>
                  </p>

                  <ul>
                    <li>
                      Designed and delivered UI/UX and logos for clients
                      website.
                    </li>
                    <li>
                      Built company subsidiary website by implementing
                      responsive web methods such as Bootstrap.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <h3 className="resume-title">Technical Skillsets</h3>

                <ul>
                  <li>
                    Javascript
                    <p>
                      <em>ReactJS, AngularJS, NodeJS, TypeScript, jQuery</em>
                    </p>
                  </li>
                  <li>
                    PHP
                    <p>
                      <em>Laravel</em>
                    </p>
                  </li>
                  <li>
                    Cloud
                    <p>
                      <em>Digital Ocean, Heroku, Google Cloud Platform</em>
                    </p>
                  </li>
                  <li>
                    Web
                    <p>
                      <em>HTML, CSS, SCSS, Tailwind, Pug</em>
                    </p>
                  </li>
                  <li>
                    Data
                    <p>
                      <em>MySQL, Postgres</em>
                    </p>
                  </li>
                  <li>
                    Digital Marketing
                    <p>
                      <em>
                        Googla Analytics, Segment.io, Matomo, Hotjar, Mixpanel,
                        Heap.io, SEO
                      </em>
                    </p>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <h3 className="resume-title">Awards &amp; Certifications</h3>

                <ul>
                  <li>
                    2019: Certified Scrum Master (CSM)
                    <p>
                      <em>Badge id: 1050787</em>
                    </p>
                  </li>
                  <li>
                    2018: [Silver] Goodbye, Good Buy
                    <p>
                      <em>MDA d Awards (Best Use of Video)</em>
                    </p>
                  </li>
                  <li>
                    2017: [Silver] Goodbye, Good Buy
                    <p>
                      <em>Malaysia Effie Awards (Brand Experience)</em>
                    </p>
                  </li>
                  <li>
                    2017: [Silver] Goodbye, Good Buy
                    <p>
                      <em>
                        Malaysia Effie Awards (Internet Services, Telco Products
                        & Services)
                      </em>
                    </p>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <h3 className="resume-title">Education</h3>
                <div className="resume-item">
                  <h4>Universiti Malaysia Sarawak (UNIMAS)</h4>
                  <h5>2012 - 2015</h5>
                  <p>
                    <em>B.Sc Cognitive Science</em>
                  </p>
                </div>
                <div className="resume-item">
                  <h4>SMK Seafield</h4>
                  <h5>2010 - 2011</h5>
                  <p>
                    <em>Malaysian Higher Education Certificati (STPM)</em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Resume;
