import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";
import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";
import { useNavigate, useParams } from "react-router-dom";

import "./article.style.scss";
import { getArticle } from "../../controllers/getArticles";
import useLoader from "../../hooks/useLoader";
import { NAV } from "../../constants/paths";

const Article = () => {
  const { setLoader, hideLoader } = useLoader();
  let { articleId } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState<any>({
    title: "",
  });
  useEffect(() => {
    setLoader();

    const script = document.createElement("script");
    script.src = "https://www.instagram.com/embed.js";
    script.async = true;
    script.onload = () => {
      dispatchEvent(new Event("load"));
      dispatchEvent(new Event("DOMContentLoaded"));
    };
    document.body.appendChild(script);
    console.log("loaded");

    if (!articleId) navigate("/articles");
    articleId &&
      getArticle(articleId).then((response) => {
        setArticle(response);
        hideLoader();
      });
  }, []);
  return (
    <>
      <Header isArticles={true} />
      <Helmet>
        <title>andriantam | {article.title || "Article"}</title>
        <meta property="og:title" content={`andriantam | ${article.title}`} />
        <meta
          property="og:image"
          content={
            article?.images?.length > 0
              ? article.images[0].url.replace("https", "http")
              : "/assets/img/hero-bg.jpg"
          }
        />
      </Helmet>
      <main id="main">
        <section
          id="article-hero"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${
              article?.images?.length > 0
                ? article.images[0].url
                : "/assets/img/article-header.jpg"
            }")`,
            backgroundPosition: `center 30%`,
          }}
        >
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h1>{article.title}</h1>
          </div>
        </section>

        <Breadcrumbs
          secondLevelTitle="Article"
          secondLevelUrl={NAV.ARTICLES}
          thirdLevelTitle={article.title}
          isArticle
        />
        <section id="article" className="article">
          <div className="container">
            <div
              className="article-content"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default Article;
